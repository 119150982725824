/**
 * @file It contains scripts for support view.
 */
import { mapGetters, mapActions } from "vuex"

export default {
  name: "Support",
  data() {
    return {
      message: undefined
    }
  },
  methods: {
    ...mapActions({
      requestSupport: "support/requestSupport",
      notify        : "shared/notify"
    })
  },
  computed: {
    ...mapGetters({
      isRequestingSupport: "support/isRequestingSupport",
      isSupportRequested : "support/isSupportRequested"
    })
  },
  watch: {
    isSupportRequested: {
      handler: function(value) {
        if (value) {
          this.notify({
            type: "success",
            text: "438"
          })
          this.message = undefined
          this.$refs.form_support.reset()
        }
      }
    }
  }
}
